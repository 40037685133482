// import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import Evidence from "./Evidence";
import Homepage from "./Homepage";

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="header">
            <b>
              <Link to="/">Buggy Spring Tools</Link>
            </b>
            <div className="description">
              Simple tools that were made for fun
            </div>
            <div className="invite">
              <a href="https://discord.gg/bbPEDNuaw8">Join our Discord</a>
            </div>
          </div>
          <Routes>
            <Route path="/evidence/:id" element={<Evidence />} />
            <Route path="/" element={<Homepage />} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
