import React, { useState } from 'react';
import { useParams } from "react-router-dom";
  
const Homepage = () => {
  // const [click, setClick] = useState(false);

  return (
    <div>
        <div className="item ban-bot">
            <div className="title">
                Discord Ban Bot
            </div>
            <div className="description">
                Help the community by flagging users as spammers and protect your Discord server from spammers. Moderators can report users by submitting evidence to the bot. If three different servers report the same user they will be automatically banned when they attempt to join a server.
            </div>
            <div className="description">
                
            </div>
            <div className="content">
                <div>
                    <div
                        className="button button-sub-text"
                        onClick={()=>{
                            window.location = "https://docs.buggyspring.tools/guides/discord-ban-bot"
                        }}
                    >Documentation</div>
                    <div
                        className="button button-active"
                        onClick={()=>{
                            window.location = "https://discord.com/oauth2/authorize?client_id=965820179315560449&scope=bot&permissions=2054"
                        }}
                    >Add To Server</div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Homepage;
