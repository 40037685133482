import React, { useState } from 'react';
import { useParams } from "react-router-dom";
  


const Evidence = () => {
  // const [click, setClick] = useState(false);

  let { id } = useParams();

  console.log("id", id)

  return (
    <div className="evidence-container">

    </div>
  );
};

export default Evidence;
